import { css } from '@emotion/react';
import { FONTS, COLOR } from '@/app/common/style/variables';
import { rem } from './functions';
import { mobile, tabletAndBelow } from '@/app/common/style/mixins';

export const h1 = css`
  font-size: ${rem(85)};
  line-height: 92px;
  letter-spacing: 0;
  margin-bottom: 64px;
`;

export const h2 = css`
  font-size: ${rem(56)};
  line-height: 68px;
  letter-spacing: 0.5px;
  margin-bottom: 48px;

  ${mobile(css`
    font-size: ${rem(30)};
    line-height: 42px;
  `)}
`;

export const h3 = css`
  font-size: ${rem(30)};
  line-height: 42px;
  letter-spacing: 0;
  margin-bottom: 40px;

  ${mobile(css`
    font-size: ${rem(22)};
    line-height: 30px;
  `)}
`;

export const h4 = css`
  font-size: ${rem(22)};
  line-height: 30px;
  letter-spacing: 0.4px;
  margin-bottom: 16px;
`;

export const h5 = css`
  font-size: ${rem(14)};
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
`;

export const body2 = css`
  font-size: ${rem(14)};
  line-height: 22px;
  letter-spacing: 0;
`;

export const body3 = css`
  font-size: ${rem(18)};
  line-height: 28px;
  letter-spacing: 0.2px;
`;

export const body4 = css`
  font-size: ${rem(16)};
  line-height: 25px;
  letter-spacing: 0.35px;
`;

export const extraBody = css`
  font-size: ${rem(18)};
  line-height: 28px;
  letter-spacing: 0.2px;
`;

export const miniHeader = css`
  font-size: ${rem(18)};
  line-height: 28px;
  color: ${COLOR.woodSmoke3};
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 24px;

  ${tabletAndBelow(css`
    font-size: ${rem(14)};
    line-height: 24px;
  `)}
`;
const Typography = css`
  /**
 * Typography
 */
  html {
    font-size: ${FONTS.sizeBase}px;
    font-family: ${FONTS.gtEesti};
    background-color: ${COLOR.whiteFade};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  span,
  p,
  .body-1,
  .body-2,
  .body-3,
  .body-4,
  .small,
  .caption,
  .link,
  .btn {
    font-family: ${FONTS.gtEestiProDisplay};
    font-weight: ${FONTS.light};
    color: ${COLOR.woodSmoke1};
    margin: 0;
  }

  a,
  .link,
  button,
  .btn {
    font-weight: ${FONTS.regular}; //$font-regular;
  }

  p,
  .body-1,
  .body-2,
  .body-3,
  .body-4 {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  .h1 {
    ${h1}
  }

  h2,
  .h2 {
    ${h2}
  }

  h3,
  .h3 {
    ${h3}
  }

  h4,
  .h4 {
    ${h4}
  }

  h5,
  .h5 {
    ${h5}
  }

  .body-1 {
    font-size: ${rem(16)};
    line-height: 26px;
    letter-spacing: 0.2px;
  }

  .body-2 {
    ${body2}
  }

  .body-3,
  p {
    ${body3}
  }

  .body-4 {
    ${body4}
  }

  .extra-body {
    ${extraBody}
  }

  .small {
    font-size: ${rem(12)};
    line-height: 18px;
    letter-spacing: 0;
  }

  .caption {
    font-size: ${rem(12)};
    line-height: 22px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    padding-left: 25px;

    li {
      &::before {
        content: '\2022';
        color: ${COLOR.woodSmoke3};
        font-weight: bold;
        margin-left: -1em;
        margin-top: 0.3em;
        position: absolute;
      }
    }
  }

  ${mobile(css`
    .body-1 {
      font-size: ${rem(16)};
      line-height: 26px;
      letter-spacing: 0.2px;
      font-weight: normal;
    }
    p {
      font-size: ${rem(16)};
      line-height: 26px;
    }
  `)}

  .disabled,
  [disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  .mini-header {
    ${miniHeader}
  }
`;

export const separator = css`
  margin: 0 12px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 10px;
  border-radius: 50%;
  background-color: ${COLOR.sycamore};
`;

export default Typography;
